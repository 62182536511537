var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"landing_two_footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer_content"},[_c('div',{staticClass:"row justify-content-between"},[_vm._m(0),_c('div',{staticClass:"col-lg-10 my-3"},[_c('div',{staticClass:"links_wrapper"},[_c('div',{staticClass:"wrapper"},[_c('h4',[_vm._v(_vm._s(_vm.$t("TITLES.essential_links")))]),_c('ul',{staticClass:"footer_links_wrapper"},[_c('li',{staticClass:"footer_link"},[_c('button',{staticClass:"active",on:{"click":function($event){return _vm.scrollToSection(
                                                'hero_section_two'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t("FOOTER.home"))+" ")])]),_c('li',{staticClass:"footer_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection(
                                                'about_section_two'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t("FOOTER.about_us"))+" ")])]),_c('li',{staticClass:"footer_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection(
                                                'our_services_two'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t("FOOTER.services"))+" ")])]),_c('li',{staticClass:"footer_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection(
                                                'testimonials_two'
                                            )}}},[_vm._v(" "+_vm._s(_vm.$t("FOOTER.customers"))+" ")])])])]),_c('div',{staticClass:"wrapper"},[_c('h4',[_vm._v(_vm._s(_vm.$t("TITLES.services")))]),_vm._m(1)]),_c('div',{staticClass:"wrapper"},[_c('h4',[_vm._v(_vm._s(_vm.$t("TITLES.contact_us")))]),_c('ul',{staticClass:"footer_contact_data_wrapper"},[_c('li',{staticClass:"footer_contact_item"},[_vm._m(2),(
                                            _vm.theFooterData.phone.length == 0
                                        )?_c('div',{staticClass:"text"},[_vm._v(" +02 256- 362-07 ")]):_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.theFooterData.phone)+" ")])]),_c('li',{staticClass:"footer_contact_item"},[_vm._m(3),(
                                            _vm.theFooterData.email.length == 0
                                        )?_c('div',{staticClass:"text"},[_vm._v(" info@gmail.com ")]):_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.theFooterData.email)+" ")])]),_c('li',{staticClass:"footer_contact_item"},[_vm._m(4),(
                                            _vm.theFooterData.address.length ==
                                            0
                                        )?_c('div',{staticClass:"text"},[_vm._v(" Egypt , Naser City , El azhar Street ")]):_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.theFooterData.address)+" ")])]),_c('li',{staticClass:"footer_contact_item"},[_c('div',{staticClass:"social"},[_c('a',{attrs:{"href":_vm.theFooterData.facebook}},[_c('i',{staticClass:"\n                                                    fab\n                                                    fa-facebook-square\n                                                "})]),_c('a',{attrs:{"href":_vm.theFooterData.instagram}},[_c('i',{staticClass:"fab fa-linkedin"})]),_c('a',{attrs:{"href":_vm.theFooterData.twitter}},[_c('i',{staticClass:"\n                                                    fab\n                                                    fa-twitter-square\n                                                "})])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-2 my-3"},[_c('div',{staticClass:"logo_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/logo/logo_light.svg"),"alt":"Langlark App Logo","width":"150","height":"120"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"footer_links_wrapper"},[_c('li',{staticClass:"footer_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v(" Translation ")])]),_c('li',{staticClass:"footer_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v(" Localization ")])]),_c('li',{staticClass:"footer_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v(" Transcription ")])]),_c('li',{staticClass:"footer_link"},[_c('a',{attrs:{"href":"#"}},[_vm._v(" Proofreading & DTP ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-phone-alt"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-envelope"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon"},[_c('i',{staticClass:"fal fa-map-marker-alt"})])}]

export { render, staticRenderFns }