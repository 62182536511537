<template>
    <div
        id="hero_section_two"
        class="hero_section_two"
        @mouseover="controlBackGroundPosition('hero_section_two')"
    >
        <!-- START:: HERO SECTION CONTENT -->
        <div class="container">
            <div class="row align-items-center">
                <!-- START:: TEXT SECTION -->
                <div class="col-md-6 order-2 order-md-1">
                    <div class="hero_text_wrapper">
                        <!-- ********** START:: TITLE ********** -->
                        <h1 class="hero_title" v-if="mainTitle.length == 0">
                            {{ $t("TITLES.professional_translation_service") }}
                        </h1>
                        <h1 class="hero_title" v-else>
                            {{ mainTitle }}
                        </h1>
                        <!-- ********** END:: TITLE ********** -->

                        <!-- ********** START:: DESC ********** -->
                        <div
                            class="text_wrapper"
                            v-if="mainTitleDesc.length == 0"
                        >
                            Lorem ipsum dolor sit amet, consetetur.Lorem ipsum
                            dolor sit amet, sit amet, consetetur.
                        </div>
                        <div class="text_wrapper" v-else>
                            {{ mainTitleDesc }}
                        </div>
                        <!-- ********** END:: DESC ********** -->

                        <div class="translation_form_wrapper">
                            <button
                                class="start_btn"
                                @click="$router.push('/landing-two/start-now')"
                            >
                                <template v-if="startNowText.length == 0">{{
                                    $t("BUTTONS.start_now")
                                }}</template>
                                <template v-else>{{ startNowText }}</template>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- END:: TEXT SECTION -->

                <!-- START:: IMAGE SECTION -->
                <div class="col-md-6 order-1 order-md-2">
                    <div class="hero_image_wrapper">
                        <img
                            src="../../../assets/media/images/landing_two/hero_section_image.png"
                            alt="Hero Section Image"
                        />
                    </div>
                </div>
                <!-- END:: IMAGE SECTION -->
            </div>
        </div>
        <!-- END:: HERO SECTION CONTENT -->

        <!-- START:: MOUSE WRAPPER -->
        <div class="mouse_wrapper">
            <button
                class="scroll_btn"
                @click="scrollToSection('about_section_two')"
            >
                <img
                    src="../../../assets/media/icons/mouse.svg"
                    width="30"
                    height="40"
                />
            </button>
        </div>
        <!-- END:: MOUSE WRAPPER -->
    </div>
</template>

<script>
export default {
    name: "LandingTwoHeroSection",

    props: {
        mainTitle: {
            type: String,
            required: true,
        },
        mainTitleDesc: {
            type: String,
            required: true,
        },
        startNowText: {
            type: String,
            required: true,
        },
    },

    methods: {
        // START:: SCROLL TO SECTION
        scrollToSection(section_id) {
            const selected_section = document.querySelector(`#${section_id}`);
            selected_section.scrollIntoView();
        },
        // END:: SCROLL TO SECTION

        // START:: CONTROL BACKGROUND POSITION
        controlBackGroundPosition(section_id) {
            const target = document.querySelector(`#${section_id}`);
            target.addEventListener("mousemove", (e) => {
                let moveX = -e.pageX / 20;
                let moveY = -e.pageY / 20;
                target.style.backgroundPosition = moveX + "px " + moveY + "px";
            });
        },
        // END:: CONTROL BACKGROUND POSITION
    },
};
</script>
