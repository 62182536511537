<template>
    <div class="spinner_wrapper">
        <img
            src="../../../assets/media/logo/logo.svg"
            alt="logo"
            width="150"
            height="150"
        />
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainLoader",
};
</script>
