<template>
    <div id="our_services_three" class="our_services_three">
        <div class="container">
            <div class="row align-items-center">
                <!-- START:: SECTION CARDS -->
                <div class="col-lg-6">
                    <div class="section_cards_wrapper">
                        <div class="row" v-if="ourServices.length == 0">
                            <!-- START:: SERVICE CARD -->
                            <div class="col-md-6 my-3">
                                <div class="service_card">
                                    <span class="card_icon">
                                        <img
                                            src="../../../assets/media/images/landing_three/icons/translation.svg"
                                            alt="Icon"
                                            width="70"
                                            height="70"
                                        />
                                    </span>
                                    <div class="card_body">
                                        <h3 class="card_title">Translation</h3>

                                        <div class="text">
                                            Translation AZ provides excellent
                                            quality and super fast Translation
                                            AZ provides excellent quality and
                                            super fast
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: SERVICE CARD -->

                            <!-- START:: SERVICE CARD -->
                            <div class="col-md-6 my-3">
                                <div class="service_card">
                                    <span class="card_icon">
                                        <img
                                            src="../../../assets/media/images/landing_three/icons/transcription.svg"
                                            alt="Icon"
                                            width="70"
                                            height="70"
                                        />
                                    </span>
                                    <div class="card_body">
                                        <h3 class="card_title">
                                            Transcription
                                        </h3>

                                        <div class="text">
                                            Translation AZ provides excellent
                                            quality and super fast Translation
                                            AZ provides excellent quality and
                                            super fast
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: SERVICE CARD -->

                            <!-- START:: SERVICE CARD -->
                            <div class="col-md-6 my-3">
                                <div class="service_card">
                                    <span class="card_icon">
                                        <img
                                            src="../../../assets/media/images/landing_three/icons/localization.svg"
                                            alt="Icon"
                                            width="70"
                                            height="70"
                                        />
                                    </span>
                                    <div class="card_body">
                                        <h3 class="card_title">Localization</h3>

                                        <div class="text">
                                            Translation AZ provides excellent
                                            quality and super fast Translation
                                            AZ provides excellent quality and
                                            super fast
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: SERVICE CARD -->

                            <!-- START:: SERVICE CARD -->
                            <div class="col-md-6 my-3">
                                <div class="service_card">
                                    <span class="card_icon">
                                        <img
                                            src="../../../assets/media/images/landing_three/icons/transcription.svg"
                                            alt="Icon"
                                            width="70"
                                            height="70"
                                        />
                                    </span>
                                    <div class="card_body">
                                        <h3 class="card_title">DTP Services</h3>

                                        <div class="text">
                                            Translation AZ provides excellent
                                            quality and super fast Translation
                                            AZ provides excellent quality and
                                            super fast
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: SERVICE CARD -->
                        </div>

                        <div class="row" v-else>
                            <!-- START:: SERVICE CARD -->
                            <div
                                class="col-md-6 my-3"
                                v-for="item in ourServices"
                                :key="item.id"
                            >
                                <div class="service_card">
                                    <span class="card_icon">
                                        <img
                                            :src="item.image"
                                            :alt="item.name"
                                            width="70"
                                            height="70"
                                        />
                                    </span>
                                    <div class="card_body">
                                        <h3 class="card_title">
                                            {{ item.name }}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <!-- END:: SERVICE CARD -->
                        </div>
                    </div>
                </div>
                <!-- END:: SECTION CARDS -->

                <!-- START:: SECTION TEXT -->
                <div class="col-lg-6">
                    <!-- START:: SECTION TITLE -->
                    <div
                        class="title_wrapper"
                        v-html="$t('TITLES.our_services_html')"
                        v-if="ourServiceTitle.length == 0"
                    ></div>
                    <div class="title_wrapper" v-else>
                        <h2>{{ ourServiceTitle }}</h2>
                    </div>
                    <!-- END:: SECTION TITLE -->

                    <div class="text_wrapper" v-if="ourServiceDesc.length == 0">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet.
                    </div>
                    <div class="text_wrapper" v-else>
                        {{ ourServiceDesc }}
                    </div>
                </div>
                <!-- START:: SECTION TEXT -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingThreeServicesSection",

    props: {
        ourServices: {
            type: Array,
            required: true,
        },
        ourServiceTitle: {
            type: String,
            required: true,
        },
        ourServiceDesc: {
            type: String,
            required: true,
        },
    },
};
</script>
