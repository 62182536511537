<template>
    <div
        id="about_section_two"
        class="about_section_two"
        @mouseover="controlBackGroundPosition('about_section_two')"
    >
        <div class="container">
            <div class="row align-items-center">
                <!-- START:: SECTION IMAGE -->
                <div class="col-lg-6">
                    <div class="section_image_wrapper">
                        <img
                            src="../../../assets/media/images/landing_two/about_section_image.png"
                            alt="About Us Section Image"
                        />
                    </div>
                </div>
                <!-- END:: SECTION IMAGE -->

                <!-- START:: SECTION TEXT -->
                <div class="col-lg-6">
                    <!-- START:: SECTION TITLE -->
                    <div
                        class="title_wrapper"
                        v-html="$t('TITLES.about_us_html')"
                        v-if="aboutTitle.length == 0"
                    ></div>
                    <div class="title_wrapper" v-else>
                        <h2>{{ aboutTitle }}</h2>
                    </div>
                    <!-- END:: SECTION TITLE -->

                    <div class="text_wrapper" v-if="aboutDesc.length == 0">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet.
                    </div>
                    <div class="text_wrapper" v-else>
                        {{ aboutDesc }}
                    </div>
                </div>
                <!-- START:: SECTION TEXT -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LandingTwoAboutSection",

    props: {
        aboutTitle: {
            type: String,
            required: true,
        },
        aboutDesc: {
            type: String,
            required: true,
        },
    },

    methods: {
        // START:: CONTROL BACKGROUND POSITION
        controlBackGroundPosition(section_id) {
            const target = document.querySelector(`#${section_id}`);
            target.addEventListener("mousemove", (e) => {
                let moveX = -e.pageX / 20;
                let moveY = -e.pageY / 20;
                target.style.backgroundPosition = moveX + "px " + moveY + "px";
            });
        },
        // END:: CONTROL BACKGROUND POSITION
    },
};
</script>
