<template>
    <footer class="landing_one_footer">
        <div class="container">
            <div class="footer_content">
                <div class="row justify-content-between">
                    <div class="col-lg-2 my-3">
                        <!-- START:: FOOTER LOGO -->
                        <div class="logo_wrapper">
                            <img
                                src="../../../assets/media/logo/logo_light.svg"
                                alt="Langlark App Logo"
                                width="150"
                                height="120"
                            />
                        </div>
                        <!-- END:: FOOTER LOGO -->
                    </div>

                    <div class="col-lg-10 my-3">
                        <div class="links_wrapper">
                            <div class="wrapper">
                                <h4>{{ $t("TITLES.essential_links") }}</h4>
                                <ul class="footer_links_wrapper">
                                    <li class="footer_link">
                                        <button
                                            class="active"
                                            @click="
                                                scrollToSection(
                                                    'hero_section_one'
                                                )
                                            "
                                        >
                                            {{ $t("FOOTER.home") }}
                                        </button>
                                    </li>

                                    <li class="footer_link">
                                        <button
                                            @click="
                                                scrollToSection(
                                                    'about_section_one'
                                                )
                                            "
                                        >
                                            {{ $t("FOOTER.about_us") }}
                                        </button>
                                    </li>

                                    <li class="footer_link">
                                        <button
                                            @click="
                                                scrollToSection(
                                                    'our_services_one'
                                                )
                                            "
                                        >
                                            {{ $t("FOOTER.services") }}
                                        </button>
                                    </li>

                                    <li class="footer_link">
                                        <button
                                            @click="
                                                scrollToSection(
                                                    'testimonials_one'
                                                )
                                            "
                                        >
                                            {{ $t("FOOTER.customers") }}
                                        </button>
                                    </li>
                                </ul>
                            </div>

                            <div class="wrapper">
                                <h4>{{ $t("TITLES.services") }}</h4>
                                <ul class="footer_links_wrapper">
                                    <li class="footer_link">
                                        <a href="#"> Translation </a>
                                    </li>

                                    <li class="footer_link">
                                        <a href="#"> Localization </a>
                                    </li>

                                    <li class="footer_link">
                                        <a href="#"> Transcription </a>
                                    </li>

                                    <li class="footer_link">
                                        <a href="#"> Proofreading & DTP </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="wrapper">
                                <h4>{{ $t("TITLES.contact_us") }}</h4>
                                <ul class="footer_contact_data_wrapper">
                                    <li class="footer_contact_item">
                                        <span class="icon">
                                            <i class="fal fa-phone-alt"></i>
                                        </span>

                                        <div
                                            class="text"
                                            v-if="
                                                theFooterData.phone.length == 0
                                            "
                                        >
                                            +02 256- 362-07
                                        </div>
                                        <div class="text" v-else>
                                            {{ theFooterData.phone }}
                                        </div>
                                    </li>

                                    <li class="footer_contact_item">
                                        <span class="icon">
                                            <i class="fal fa-envelope"></i>
                                        </span>

                                        <div
                                            class="text"
                                            v-if="
                                                theFooterData.email.length == 0
                                            "
                                        >
                                            info@gmail.com
                                        </div>
                                        <div class="text" v-else>
                                            {{ theFooterData.email }}
                                        </div>
                                    </li>

                                    <li class="footer_contact_item">
                                        <span class="icon">
                                            <i
                                                class="fal fa-map-marker-alt"
                                            ></i>
                                        </span>

                                        <div
                                            class="text"
                                            v-if="
                                                theFooterData.address.length ==
                                                0
                                            "
                                        >
                                            Egypt , Naser City , El azhar Street
                                        </div>
                                        <div class="text" v-else>
                                            {{ theFooterData.address }}
                                        </div>
                                    </li>

                                    <li class="footer_contact_item">
                                        <div class="social">
                                            <a :href="theFooterData.facebook">
                                                <i
                                                    class="
                                                        fab
                                                        fa-facebook-square
                                                    "
                                                ></i>
                                            </a>

                                            <a :href="theFooterData.instagram">
                                                <i class="fab fa-linkedin"></i>
                                            </a>

                                            <a :href="theFooterData.twitter">
                                                <i
                                                    class="
                                                        fab
                                                        fa-twitter-square
                                                    "
                                                ></i>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

export default {
    name: "TheFooter",

    props: {
        theFooterData: {
            type: Object,
            required: true,
        },
    },

    computed: {
        // START:: VUEX GET AUTHENTICATED USER DATA
        ...mapGetters("AuthenticationModule", ["getAuthenticatedUserData"]),
        // END:: VUEX GET AUTHENTICATED USER DATA
    },

    data() {
        return {
            // START:: CURRENT YEAR DATA
            currentYear: new Date().getFullYear(),
            // END:: CURRENT YEAR DATA
        };
    },

    methods: {
        // START:: SCROLL TO SECTION
        scrollToSection(section_id) {
            const selected_section = document.querySelector(`#${section_id}`);
            selected_section.scrollIntoView();
        },
        // END:: SCROLL TO SECTION
    },
};
</script>
