<template>
    <div
        id="testimonials_two"
        class="testimonials_two"
        @mouseover="controlBackGroundPosition('testimonials_two')"
    >
        <!-- START:: SECTION TITLE -->
        <div
            class="title_wrapper"
            v-html="$t('TITLES.what_clients_say_html')"
        ></div>
        <!-- END:: SECTION TITLE -->

        <!-- START:: TESTIMONIALS CAROUSEL -->
        <div class="carousel_wrapper mt-5" v-if="customersSay.length == 0">
            <Carousel
                dir="ltr"
                :nav="true"
                :dots="false"
                :items="1"
                :margin="15"
                :loop="true"
                :autoplay="true"
                :autoplayTimeout="6000"
                :dragEndSpeed="2000"
                :smartSpeed="4000"
            >
                <!-- START::  TESTIMONIAL CARD -->
                <div class="testimonial_card_wrapper">
                    <div class="row align-items-center">
                        <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                        <div class="col-lg-4">
                            <div class="testimonial_image">
                                <img
                                    src="../../../assets/media/images/landing_two/client.png"
                                    alt="Client Name"
                                    width="150"
                                    height="150"
                                />
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                        <!-- START:: TESTIMONIAL BODY CARD -->
                        <div class="col-lg-8">
                            <div
                                class="testimonial_body_card"
                                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                            >
                                <div class="body_icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="body_text_wrapper">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ipsum dolor sit
                                    amet. Lorem ipsum dolor sit elitr, sed diam
                                    nonumy eirmod tempor invidunt ut labore et
                                    dolore magna aliquyam erat, sed diam
                                    voluptua. At vero eos et accusam et justo
                                    duo dolores et ea rebum. Stet clita kasd
                                    gubergren, no sea takimata sanctus est Lorem
                                    ipsum dolor sit amet. Lorem ipsum dolor sit
                                </div>
                                <div class="client_info">
                                    <div class="wrapper">
                                        <h3 class="name">Yasmin Nour</h3>
                                        <h4 class="role">Instructor</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL BODY CARD -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL CARD -->

                <!-- START::  TESTIMONIAL CARD -->
                <div class="testimonial_card_wrapper">
                    <div class="row align-items-center">
                        <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                        <div class="col-lg-4">
                            <div class="testimonial_image">
                                <img
                                    src="../../../assets/media/images/landing_two/client.png"
                                    alt="Client Name"
                                    width="150"
                                    height="150"
                                />
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                        <!-- START:: TESTIMONIAL BODY CARD -->
                        <div class="col-lg-8">
                            <div
                                class="testimonial_body_card"
                                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                            >
                                <div class="body_icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="body_text_wrapper">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ipsum dolor sit
                                    amet. Lorem ipsum dolor sit elitr, sed diam
                                    nonumy eirmod tempor invidunt ut labore et
                                    dolore magna aliquyam erat, sed diam
                                    voluptua. At vero eos et accusam et justo
                                    duo dolores et ea rebum. Stet clita kasd
                                    gubergren, no sea takimata sanctus est Lorem
                                    ipsum dolor sit amet. Lorem ipsum dolor sit
                                </div>
                                <div class="client_info">
                                    <div class="wrapper">
                                        <h3 class="name">Yasmin Nour</h3>
                                        <h4 class="role">Instructor</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL BODY CARD -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL CARD -->

                <!-- START::  TESTIMONIAL CARD -->
                <div class="testimonial_card_wrapper">
                    <div class="row align-items-center">
                        <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                        <div class="col-lg-4">
                            <div class="testimonial_image">
                                <img
                                    src="../../../assets/media/images/landing_two/client.png"
                                    alt="Client Name"
                                    width="150"
                                    height="150"
                                />
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                        <!-- START:: TESTIMONIAL BODY CARD -->
                        <div class="col-lg-8">
                            <div
                                class="testimonial_body_card"
                                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                            >
                                <div class="body_icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="body_text_wrapper">
                                    Lorem ipsum dolor sit amet, consetetur
                                    sadipscing elitr, sed diam nonumy eirmod
                                    tempor invidunt ut labore et dolore magna
                                    aliquyam erat, sed diam voluptua. At vero
                                    eos et accusam et justo duo dolores et ea
                                    rebum. Stet clita kasd gubergren, no sea
                                    takimata sanctus est Lorem ipsum dolor sit
                                    amet. Lorem ipsum dolor sit elitr, sed diam
                                    nonumy eirmod tempor invidunt ut labore et
                                    dolore magna aliquyam erat, sed diam
                                    voluptua. At vero eos et accusam et justo
                                    duo dolores et ea rebum. Stet clita kasd
                                    gubergren, no sea takimata sanctus est Lorem
                                    ipsum dolor sit amet. Lorem ipsum dolor sit
                                </div>
                                <div class="client_info">
                                    <div class="wrapper">
                                        <h3 class="name">Yasmin Nour</h3>
                                        <h4 class="role">Instructor</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL BODY CARD -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL CARD -->
            </Carousel>
        </div>
        <!-- END:: TESTIMONIALS CAROUSEL -->

        <!-- START:: TESTIMONIALS CAROUSEL -->
        <div class="carousel_wrapper mt-5" v-else>
            <Carousel
                dir="ltr"
                :nav="true"
                :dots="false"
                :items="1"
                :margin="15"
                :loop="true"
                :autoplay="true"
                :autoplayTimeout="6000"
                :dragEndSpeed="2000"
                :smartSpeed="4000"
            >
                <!-- START::  TESTIMONIAL CARD -->
                <div
                    class="testimonial_card_wrapper"
                    v-for="item in customersSay"
                    :key="item.id"
                >
                    <div class="row align-items-center">
                        <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                        <div class="col-lg-4">
                            <div class="testimonial_image">
                                <img
                                    :src="item.image"
                                    :alt="item.fullname"
                                    width="150"
                                    height="150"
                                />
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                        <!-- START:: TESTIMONIAL BODY CARD -->
                        <div class="col-lg-8">
                            <div
                                class="testimonial_body_card"
                                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                            >
                                <div class="body_icon">
                                    <i class="fas fa-quote-left"></i>
                                </div>
                                <div class="body_text_wrapper">
                                    {{ item.say }}
                                </div>
                                <div class="client_info">
                                    <div class="wrapper">
                                        <h3 class="name">
                                            {{ item.fullname }}
                                        </h3>
                                        <h4 class="role">{{ item.job }}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END:: TESTIMONIAL BODY CARD -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL CARD -->
            </Carousel>
        </div>
        <!-- END:: TESTIMONIALS CAROUSEL -->
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING OWL CAROUSEL
import Carousel from "vue-owl-carousel";
// END:: IMPORTING OWL CAROUSEL

export default {
    name: "LandingTwoTestimonialsSection",

    components: {
        Carousel,
    },

    props: {
        customersSay: {
            type: Array,
            required: true,
        },
    },

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG
    },

    data() {
        return {};
    },

    methods: {
        // START:: CONTROL BACKGROUND POSITION
        controlBackGroundPosition(section_id) {
            const target = document.querySelector(`#${section_id}`);
            target.addEventListener("mousemove", (e) => {
                let moveX = -e.pageX / 20;
                let moveY = -e.pageY / 20;
                target.style.backgroundPosition = moveX + "px " + moveY + "px";
            });
        },
        // END:: CONTROL BACKGROUND POSITION
    },
};
</script>
