var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"landing_three_header",attrs:{"id":"navbar"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"navbar_wrapper"},[_c('div',{staticClass:"logo_wrapper"},[_c('router-link',{staticClass:"logo_wrapper",attrs:{"to":"/landing-three"}},[_c('img',{attrs:{"src":require("../../../assets/media/logo/logo.svg"),"alt":"Langlark App Logo","width":"81","height":"67"}})])],1),_c('div',{staticClass:"links_wrapper"},[_c('ul',{staticClass:"navbar_links_wrapper"},[_c('li',{staticClass:"navbar_link"},[_c('button',{staticClass:"active",on:{"click":function($event){return _vm.scrollToSection('hero_section_three')}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.home"))+" ")])]),_c('li',{staticClass:"navbar_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection('about_section_three')}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.about_us"))+" ")])]),_c('li',{staticClass:"navbar_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection('our_services_three')}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.services"))+" ")])]),_c('li',{staticClass:"navbar_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection('testimonials_three')}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.customers"))+" ")])]),_c('li',{staticClass:"navbar_link"},[_c('button',{on:{"click":function($event){return _vm.scrollToSection('contact_us_three')}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.contact_us"))+" ")])])])]),_c('div',{staticClass:"navbar_btns_wrapper"},[_c('div',{staticClass:"lang_menu_wrapper"},[_c('button',{staticClass:"lang_menu_btn",on:{"click":function($event){$event.stopPropagation();return _vm.toggleLangMenu.apply(null, arguments)}}},[_c('div',{staticClass:"selected_lang_wrapper"},[_c('div',{staticClass:"icons_wrapper"},[_vm._m(0),_c('span',[_c('i',{staticClass:"fal fa-angle-down",class:{
                                            rotate_arrow: _vm.langMenuIsOpen,
                                        }})])]),_c('div',{staticClass:"lang_flag_wrapper"},[(_vm.getAppLocale == 'en')?_c('img',{attrs:{"src":require("../../../assets/media/flags/eng_flag.png"),"width":"30","height":"20"}}):_c('img',{attrs:{"src":require("../../../assets/media/flags/ar_flag.png"),"width":"30","height":"20"}})])])]),_c('transition',{attrs:{"name":"fadeInUp","mode":"out-in"}},[(_vm.langMenuIsOpen && _vm.appActiveLang == 'both')?_c('ul',{staticClass:"user_profile_menu"},[_c('li',{staticClass:"lang_menu_item",on:{"click":function($event){$event.stopPropagation();_vm.toggleLangMenu();
                                    _vm.changeAppLanguage({
                                        newLang: 'en',
                                        firedInMounted: false,
                                    });}}},[_c('button',[_c('div',{staticClass:"lang_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/flags/eng_flag.png"),"width":"30","height":"20"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.english"))+" ")])])])]),_c('li',{staticClass:"lang_menu_item",on:{"click":function($event){$event.stopPropagation();_vm.toggleLangMenu();
                                    _vm.changeAppLanguage({
                                        newLang: 'ar',
                                        firedInMounted: false,
                                    });}}},[_c('button',[_c('div',{staticClass:"lang_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/flags/ar_flag.png"),"width":"30","height":"20"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.arabic"))+" ")])])])])]):_vm._e()])],1),_c('button',{staticClass:"small_screens_navbar_toggeler",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fal fa-bars"})])])])]),_c('div',{staticClass:"small_screens_navbar_wrapper",class:{ active: _vm.smallScreensNavbarIsOpen }},[_c('button',{staticClass:"close_btn",on:{"click":_vm.toggleSmallScreensNavBar}},[_c('i',{staticClass:"fal fa-times"})]),_c('ul',{staticClass:"small_screens_links"},[_c('li',[_c('button',{staticClass:"active",on:{"click":function($event){_vm.toggleSmallScreensNavBar();
                        _vm.scrollToSection('hero_section_three');}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.home"))+" ")])]),_c('li',[_c('button',{on:{"click":function($event){_vm.toggleSmallScreensNavBar();
                        _vm.scrollToSection('about_section_three');}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.about_us"))+" ")])]),_c('li',[_c('button',{on:{"click":function($event){_vm.toggleSmallScreensNavBar();
                        _vm.scrollToSection('our_services_three');}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.services"))+" ")])]),_c('li',[_c('button',{on:{"click":function($event){_vm.toggleSmallScreensNavBar();
                        _vm.scrollToSection('testimonials_three');}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.customers"))+" ")])]),_c('li',[_c('button',{on:{"click":function($event){_vm.toggleSmallScreensNavBar();
                        _vm.scrollToSection('contact_us_three');}}},[_vm._v(" "+_vm._s(_vm.$t("NAVBAR.contact_us"))+" ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fal fa-globe"})])}]

export { render, staticRenderFns }