<template>
    <div id="testimonials_three" class="testimonials_three">
        <!-- START:: SECTION TITLE -->
        <div
            class="title_wrapper"
            v-html="$t('TITLES.what_clients_say_html')"
        ></div>
        <!-- END:: SECTION TITLE -->

        <!-- START::  TESTIMONIAL CARD -->
        <div class="testimonial_card_wrapper" v-if="customersSay.length == 0">
            <div class="row align-items-center">
                <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                <div
                    class="
                        col-lg-6
                        d-flex
                        align-items-center
                        justify-content-center
                    "
                >
                    <div class="testimonial_images_wrapper">
                        <!-- START:: MAIN IMAGE -->
                        <div class="main_image_wrapper">
                            <img
                                class="main_testimonials_image"
                                :src="selectedTestimonial.image"
                                :alt="selectedTestimonial.name"
                                width="120"
                                height="120"
                            />
                        </div>
                        <!-- END:: MAIN IMAGE -->

                        <!-- START::  TESTIMONIALS THUMBNAILS -->
                        <button
                            v-for="thumbnail in allTestimonials"
                            :key="thumbnail.id"
                            class="thumbnail_wrapper"
                            @click="changeTestimonialCard(thumbnail)"
                        >
                            <img
                                class="main_testimonials_image"
                                :src="thumbnail.image"
                                :alt="thumbnail.name"
                                width="120"
                                height="120"
                            />
                        </button>
                        <!-- END::  TESTIMONIALS THUMBNAILS -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                <!-- START:: TESTIMONIAL BODY CARD -->
                <div class="col-lg-6">
                    <div class="testimonial_body_card">
                        <div class="body_icon">
                            <i class="fas fa-quote-left"></i>
                        </div>
                        <div class="body_text_wrapper">
                            {{ selectedTestimonial.content }}
                        </div>
                        <div class="client_info">
                            <div class="wrapper">
                                <h3 class="name">
                                    {{ selectedTestimonial.name }}
                                </h3>
                                <h4 class="role">
                                    {{ selectedTestimonial.role }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END:: TESTIMONIAL BODY CARD -->
            </div>
        </div>
        <!-- END:: TESTIMONIAL CARD -->

        <!-- START::  TESTIMONIAL CARD -->
        <div class="testimonial_card_wrapper" v-else>
            <div class="row align-items-center">
                <!-- START:: TESTIMONIAL IMAGE WRAPPER -->
                <div
                    class="
                        col-lg-6
                        d-flex
                        align-items-center
                        justify-content-center
                    "
                >
                    <div class="testimonial_images_wrapper">
                        <!-- START:: MAIN IMAGE -->
                        <div class="main_image_wrapper">
                            <img
                                class="main_testimonials_image"
                                :src="selectedTestimonial.image"
                                :alt="selectedTestimonial.fullname"
                                width="120"
                                height="120"
                            />
                        </div>
                        <!-- END:: MAIN IMAGE -->

                        <!-- START::  TESTIMONIALS THUMBNAILS -->
                        <button
                            v-for="thumbnail in customersSay"
                            :key="thumbnail.id"
                            class="thumbnail_wrapper"
                            @click="changeTestimonialCard(thumbnail)"
                        >
                            <img
                                class="main_testimonials_image"
                                :src="thumbnail.image"
                                :alt="thumbnail.fullname"
                                width="120"
                                height="120"
                            />
                        </button>
                        <!-- END::  TESTIMONIALS THUMBNAILS -->
                    </div>
                </div>
                <!-- END:: TESTIMONIAL IMAGE WRAPPER -->

                <!-- START:: TESTIMONIAL BODY CARD -->
                <div class="col-lg-6">
                    <div class="testimonial_body_card">
                        <div class="body_icon">
                            <i class="fas fa-quote-left"></i>
                        </div>
                        <div class="body_text_wrapper">
                            {{ selectedTestimonial.say }}
                        </div>
                        <div class="client_info">
                            <div class="wrapper">
                                <h3 class="name">
                                    {{ selectedTestimonial.fullname }}
                                </h3>
                                <h4 class="role">
                                    {{ selectedTestimonial.job }}
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END:: TESTIMONIAL BODY CARD -->
            </div>
        </div>
        <!-- END:: TESTIMONIAL CARD -->
    </div>
</template>

<script>
// START:: IMPORT IMAGES
import client1 from "../../../assets/media/images/landing_three/client1.png";
import client2 from "../../../assets/media/images/landing_three/client2.png";
import client3 from "../../../assets/media/images/landing_three/client3.png";
import client4 from "../../../assets/media/images/landing_three/client4.png";
// START:: IMPORT IMAGES
export default {
    name: "LandingThreeTestimonialsSection",

    props: {
        customersSay: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            // START:: SELECTED TESTIMONIAL DATA
            selectedTestimonial: null,
            // END:: SELECTED TESTIMONIAL DATA

            // START:: ALL TESTIMONIALS DATA
            allTestimonials: [
                {
                    id: 1,
                    name: "Ahmed Hussien",
                    role: "Instructor",
                    image: client1,
                    content: `
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna 
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo 
                        duo dolores et ea rebum. Stet clita kasd gubergren, no sea 
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    `,
                },
                {
                    id: 2,
                    name: "Yasmin Nour",
                    role: "Instructor",
                    image: client2,
                    content: `
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna 
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    `,
                },
                {
                    id: 3,
                    name: "Ahmed Hussien",
                    role: "Instructor",
                    image: client3,
                    content: `
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna 
                        aliquyam erat, sed diam voluptua. At vero eos et accusam et justo 
                        duo dolores et ea rebum. Stet clita kasd gubergren, no sea 
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    `,
                },
                {
                    id: 4,
                    name: "Yasmin Nour",
                    role: "Instructor",
                    image: client4,
                    content: `
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed 
                        diam nonumy eirmod tempor invidunt ut labore et dolore magna 
                        takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
                    `,
                },
            ],
            // END:: ALL TESTIMONIALS DATA
        };
    },

    methods: {
        // START:: CHANGE TESTIMONIAL CARD
        changeTestimonialCard(testimonial) {
            this.selectedTestimonial = testimonial;
        },
        // END:: CHANGE TESTIMONIAL CARD
    },

    mounted() {
        // START:: SET INITIAL TESTIMONIAL
        if (this.customersSay.length != 0) {
            this.selectedTestimonial = this.customersSay[0];
            this.customersSay = this.customersSay.slice(0, 4);
        } else {
            this.selectedTestimonial = this.allTestimonials[0];
            this.allTestimonials = this.allTestimonials.slice(0, 4);
        }
        // END:: SET INITIAL TESTIMONIAL
    },
};
</script>
