<template>
    <div id="our_services_one" class="our_services_one">
        <div class="container">
            <!-- START:: SECTION TITLE -->
            <div class="title_wrapper">
                <h2 v-if="ourServiceTitle.length == 0">
                    {{ $t("TITLES.our_services") }}
                </h2>
                <h2 v-else>{{ ourServiceTitle }}</h2>
                <span></span>
            </div>
            <!-- END:: SECTION TITLE -->

            <!-- START:: SERVICES CAROUSEL -->
            <div
                class="carousel_wrapper mt-5"
                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                v-if="ourServices.length == 0"
            >
                <Carousel
                    dir="ltr"
                    :nav="false"
                    :dots="true"
                    :items="4"
                    :margin="15"
                    :loop="true"
                    :autoplay="true"
                    :autoplayTimeout="6000"
                    :dragEndSpeed="2000"
                    :smartSpeed="2000"
                    :responsive="{
                        0: {
                            items: 1,
                        },
                        750: {
                            items: 2,
                        },
                        900: {
                            items: 4,
                        },
                    }"
                >
                    <!-- START:: SERVICE CARD -->
                    <div class="service_card_wrapper">
                        <div class="card_icon">
                            <img
                                src="../../../assets/media/icons/landing_one/translate.svg"
                                alt="icon"
                                width="85"
                                height="85"
                            />
                        </div>
                        <h4 class="card_header">Translation</h4>
                        <div class="card_text">
                            Translation AZ provides excellent quality and super
                            fast results at the most competitive rates in the
                            industry.
                        </div>
                    </div>
                    <!-- END:: SERVICE CARD -->

                    <!-- START:: SERVICE CARD -->
                    <div class="service_card_wrapper">
                        <div class="card_icon">
                            <img
                                src="../../../assets/media/icons/landing_one/localization.svg"
                                alt="icon"
                                width="85"
                                height="85"
                            />
                        </div>
                        <h4 class="card_header">Localization</h4>
                        <div class="card_text">
                            Translation AZ provides excellent quality and super
                            fast results at the most competitive rates in the
                            industry.
                        </div>
                    </div>
                    <!-- END:: SERVICE CARD -->

                    <!-- START:: SERVICE CARD -->
                    <div class="service_card_wrapper">
                        <div class="card_icon">
                            <img
                                src="../../../assets/media/icons/landing_one/transcription.svg"
                                alt="icon"
                                width="85"
                                height="85"
                            />
                        </div>
                        <h4 class="card_header">Transcription</h4>
                        <div class="card_text">
                            Translation AZ provides excellent quality and super
                            fast results at the most competitive rates in the
                            industry.
                        </div>
                    </div>
                    <!-- END:: SERVICE CARD -->

                    <!-- START:: SERVICE CARD -->
                    <div class="service_card_wrapper">
                        <div class="card_icon">
                            <img
                                src="../../../assets/media/icons/landing_one/localization.svg"
                                alt="icon"
                                width="85"
                                height="85"
                            />
                        </div>
                        <h4 class="card_header">Localization</h4>
                        <div class="card_text">
                            Translation AZ provides excellent quality and super
                            fast results at the most competitive rates in the
                            industry.
                        </div>
                    </div>
                    <!-- END:: SERVICE CARD -->
                </Carousel>
            </div>

            <div
                class="carousel_wrapper mt-5"
                :dir="getAppLocale == 'ar' ? 'rtl' : 'ltr'"
                v-else
            >
                <Carousel
                    dir="ltr"
                    :nav="false"
                    :dots="true"
                    :items="4"
                    :margin="15"
                    :loop="true"
                    :autoplay="true"
                    :autoplayTimeout="6000"
                    :dragEndSpeed="2000"
                    :smartSpeed="2000"
                    :responsive="{
                        0: {
                            items: 1,
                        },
                        750: {
                            items: 2,
                        },
                        900: {
                            items: 4,
                        },
                    }"
                >
                    <!-- START:: SERVICE CARD -->
                    <div
                        class="service_card_wrapper"
                        v-for="item in ourServices"
                        :key="item.id"
                    >
                        <div class="card_icon">
                            <img
                                :src="item.image"
                                :alt="item.name"
                                width="85"
                                height="85"
                            />
                        </div>
                        <h4 class="card_header">
                            {{ item.name }}
                        </h4>
                    </div>
                    <!-- END:: SERVICE CARD -->
                </Carousel>
            </div>
            <!-- END:: SERVICES CAROUSEL -->
        </div>
    </div>
</template>

<script>
// START:: IMPORTING VUEX HELPERS
import { mapGetters } from "vuex";
// END:: IMPORTING VUEX HELPERS

// START:: IMPORTING OWL CAROUSEL
import Carousel from "vue-owl-carousel";
// END:: IMPORTING OWL CAROUSEL

export default {
    name: "LandingOneServicesSection",

    components: {
        Carousel,
    },

    props: {
        ourServices: {
            type: Array,
            required: true,
        },
        ourServiceTitle: {
            type: String,
            required: true,
        },
        ourServiceDesc: {
            type: String,
            required: true,
        },
    },

    computed: {
        // START:: VUEX GET APP LANG
        ...mapGetters("AppLangModule", ["getAppLocale"]),
        // END:: VUEX GET APP LANG
    },
};
</script>
