var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"our_customers_two",attrs:{"id":"our_customers_two"}},[_c('div',{staticClass:"title_wrapper",domProps:{"innerHTML":_vm._s(_vm.$t('TITLES.our_customers_html'))}}),_c('div',{staticClass:"customers_carousel_wrapper mt-5",attrs:{"dir":_vm.getAppLocale == 'ar' ? 'rtl' : 'ltr'}},[(_vm.customers.length == 0)?_c('div',{staticClass:"container"},[_c('Carousel',{attrs:{"dir":"ltr","nav":false,"dots":false,"items":4,"margin":15,"loop":true,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
                    0: {
                        items: 2,
                    },
                    750: {
                        items: 2,
                    },
                    900: {
                        items: 4,
                    },
                }}},[_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/images/logos/logo1.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/images/logos/logo2.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/images/logos/logo3.png"),"alt":"Customer Name","width":"120","height":"120"}})]),_c('div',{staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":require("../../../assets/media/images/logos/logo4.png"),"alt":"Customer Name","width":"120","height":"120"}})])])],1):_c('div',{staticClass:"container"},[_c('Carousel',{attrs:{"dir":"ltr","nav":false,"dots":false,"items":4,"margin":15,"loop":true,"autoplay":true,"autoplayTimeout":6000,"dragEndSpeed":2000,"smartSpeed":2000,"responsive":{
                    0: {
                        items: 2,
                    },
                    750: {
                        items: 2,
                    },
                    900: {
                        items: 4,
                    },
                }}},_vm._l((_vm.customers),function(item){return _c('div',{key:item.id,staticClass:"customer_logo_wrapper"},[_c('img',{attrs:{"src":item.image,"width":"120","height":"120"}})])}),0)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }